// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---packages-mag-theme-src-pages-404-tsx": () => import("./../../packages/mag-theme/src/pages/404.tsx" /* webpackChunkName: "component---packages-mag-theme-src-pages-404-tsx" */),
  "component---packages-mag-theme-src-pages-archive-tsx": () => import("./../../packages/mag-theme/src/pages/archive.tsx" /* webpackChunkName: "component---packages-mag-theme-src-pages-archive-tsx" */),
  "component---packages-mag-theme-src-pages-author-tsx": () => import("./../../packages/mag-theme/src/pages/author.tsx" /* webpackChunkName: "component---packages-mag-theme-src-pages-author-tsx" */),
  "component---packages-mag-theme-src-pages-category-tsx": () => import("./../../packages/mag-theme/src/pages/category.tsx" /* webpackChunkName: "component---packages-mag-theme-src-pages-category-tsx" */),
  "component---packages-mag-theme-src-pages-tag-tsx": () => import("./../../packages/mag-theme/src/pages/tag.tsx" /* webpackChunkName: "component---packages-mag-theme-src-pages-tag-tsx" */),
  "component---packages-mag-theme-src-templates-authors-tsx": () => import("./../../packages/mag-theme/src/templates/authors.tsx" /* webpackChunkName: "component---packages-mag-theme-src-templates-authors-tsx" */),
  "component---packages-mag-theme-src-templates-categories-tsx": () => import("./../../packages/mag-theme/src/templates/categories.tsx" /* webpackChunkName: "component---packages-mag-theme-src-templates-categories-tsx" */),
  "component---packages-mag-theme-src-templates-page-tsx": () => import("./../../packages/mag-theme/src/templates/page.tsx" /* webpackChunkName: "component---packages-mag-theme-src-templates-page-tsx" */),
  "component---packages-mag-theme-src-templates-post-tsx": () => import("./../../packages/mag-theme/src/templates/post.tsx" /* webpackChunkName: "component---packages-mag-theme-src-templates-post-tsx" */),
  "component---packages-mag-theme-src-templates-posts-tsx": () => import("./../../packages/mag-theme/src/templates/posts.tsx" /* webpackChunkName: "component---packages-mag-theme-src-templates-posts-tsx" */),
  "component---packages-mag-theme-src-templates-tags-tsx": () => import("./../../packages/mag-theme/src/templates/tags.tsx" /* webpackChunkName: "component---packages-mag-theme-src-templates-tags-tsx" */)
}

